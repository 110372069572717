import React from 'react'
import {graphql} from 'gatsby'
import { getRecentPostsFromEdges } from '../../utils/utilFunctions'
import {Container, Row, Col} from 'reactstrap'
import SEO from "../../components/seo"
import Layout from "../../containers/layout/layout"
import Header from '../../containers/layout/header'
import Footer from '../../containers/layout/footer'
import Heading from '../../components/shared/heading'
import Text from '../../components/shared/text'
import Blog from '../../components/blog/layout-two'
import SearchWidget from '../../containers/widgets/search'
import RecentPostWidget from '../../containers/widgets/recent-post'
import CategoryWidget from '../../containers/widgets/categories'
import {SectionWrap, PageHeader, BlogListWrap, SidebarWrap} from './date-template.stc'

const DateTemplate = ({ data, pageContext, location, ...restProps }) => {
    const [author] = data.allContentfulPerson.edges;
    const {date} = pageContext;
    const {categoryText, categoryHeading, blogStyle} = restProps
    const { categoriesSummary } = pageContext;

    const contentfulPosts = data.allContentfulBlogPost.edges.map( ({ node }) => {
        const dateSplit = node.date.split(" ");
        const {text: quote_text,author: quote_author} = node.quote !== null ? node.quote : { };
  
        return {
            node: {
                fields: {
                    slug: node.slug,
                    collection: 'contentfulPosts',
                    dateSlug: node.dateSlug
                },
                frontmatter: {
                    sortKey: node.sortKey,
                    date: node.date,
                    format: node.format,
                    title: node.title,
                    video_link: node.video_link,
                    quote_text: quote_text,
                    quote_author: quote_author,
                    link: node.link,
                    image: node.image,
                    images: node.images,
                    category: node.category,
                },
                excerpt: node.body.childMarkdownRemark.excerpt,
            }
        }
    });

    let posts = contentfulPosts;
    const totalCount = data.allContentfulBlogPost.totalCount;

    const recentFilesystemPosts = [];
    const recentContentfulPosts = data.recentContentfulBlogPost.edges;
    const recentPosts = getRecentPostsFromEdges(recentFilesystemPosts, recentContentfulPosts);

    return (
        <Layout>
            <SEO title={`Date: ${date}`}/>
            <Header authorData={author.node}/>
            <div className="main-content">
                <SectionWrap>
                    <Container>
                        <Row>
                            <Col xl={8}>
                                <PageHeader>
                                    <Text {...categoryText}>{date}</Text>
                                    <Heading {...categoryHeading}>A collection of {totalCount} post</Heading>
                                </PageHeader>
                                <BlogListWrap>
                                    {posts.map(blog => (
                                        <Blog
                                            {...blogStyle}
                                            key={blog.node.fields.slug}
                                            content={{
                                                ...blog.node.fields, 
                                                ...blog.node.frontmatter,
                                                excerpt: blog.node.excerpt
                                            }}
                                        />
                                    ))}
                                </BlogListWrap>
                            </Col>
                            <Col xl={4}>
                                <SidebarWrap>
                                    <SearchWidget/>
                                    <RecentPostWidget recentPosts={recentPosts}/>
                                    <CategoryWidget categoriesSummary={categoriesSummary}/>
                                </SidebarWrap>
                            </Col>
                        </Row>
                    </Container>
                </SectionWrap>
            </div>
            <Footer/>
        </Layout>
    )
}

export const query = graphql `
    query BlogByDateQuery($dateSlug: Date!){
        recentContentfulBlogPost: allContentfulBlogPost(
            filter: { format : { eq: "image" } }
            sort: {order: DESC, fields: publishDate}
            limit: 4
        ) {
            edges {
                node {
                    ...RecentContentfulBlogPostField
                }
            }
        }        
        allContentfulBlogPost(
            filter: { dateSlug: {eq: $dateSlug} },
            sort: {order: DESC, fields: publishDate}
        ) {
            totalCount
            edges {
                node {
                    ...MajorContentfulBlogPostField
                    body {
                        childMarkdownRemark {
                            excerpt(format: PLAIN, pruneLength: 165)
                        }
                    }
                }
            }
        }
        allContentfulPerson(filter: { id: { eq: "801d0181-aa93-5da9-82f1-fae3b71554af" } }) {
            edges {
                node {
                    ...ContentfulPersonField
                }
            }
        }
    }
`;

DateTemplate.defaultProps = {
    categoryText: {
        textTransform: 'capitalize',
        mb: '11px'
    },
    categoryHeading: {
        as: 'h4',
        color: 'secondaryColor'
    },
    blogStyle: {
        mb: '30px'
    },
    categoriesSummary: {},
}

export default DateTemplate
 